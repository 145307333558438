import prop from 'lodash/fp/prop'
import * as Sentry from '@sentry/nextjs'
import isOrganicPageView from '../vertical-helpers/is-organic-page-view'
import { readOriginFromSession } from '../landing-page/read-origin-from-session'

/**
 * This is the list of phone numbers that the Ringba
 * script will search for and replace in order to implement the
 * corresponding Number Pool. If a new Number Pool is
 * created in Ringba, its default number should be added to this list:
 */
export const PHONE_NUMBERS = {
  'auto-insurance': '1.833.513.2483',
  'auto-insurance-organic': '1.833.513.2483', //same as paid ad
  'health-insurance': '1.866.791.1509',
  'health-insurance-organic': '1.321.471.2179',
  u65: '1.844.693.0696',
  'u65-organic': '1.888.915.9154',
  'g-u65': '1.727.349.2522',
  'medicare-gads': '1.844.673.1431',
  'landing-u65': '1.844.693.0696',
  'landing-medicare': '1.877.423.0028',
  'landing-g-u65': '1.888.471.1901',
}

export function getPhoneNumber(category: string) {
  if (typeof window === 'undefined') return ''
  const isOrganic = isOrganicPageView()
  const session = readOriginFromSession()
  const origin = session || window.location.href
  const defaultCategory: 'u65' | 'u65-organic' = isOrganic
    ? 'u65'
    : 'u65-organic'
  const phoneNumber = prop(
    category + (isOrganic ? '-organic' : ''),
    PHONE_NUMBERS,
  )

  if (origin.includes('landing/u65/')) {
    return PHONE_NUMBERS['landing-u65']
  }
  if (origin.includes('landing/g-u65/')) {
    return PHONE_NUMBERS['landing-g-u65']
  }
  if (window.location.href.includes('g-medicare')) {
    return PHONE_NUMBERS['medicare-gads']
  }
  if (window.location.href.includes('g-u65')) {
    return PHONE_NUMBERS['g-u65']
  }

  if (origin.includes('landing/medicare/')) {
    return PHONE_NUMBERS['landing-medicare']
  }
  if (
    origin.includes('medicare/advantage-sem') ||
    !isOrganicPageView('google')
  ) {
    return PHONE_NUMBERS['medicare-gads']
  }
  if (
    typeof window !== 'undefined' &&
    window.location.pathname === '/medicare/advantage-compare/'
  ) {
    return '1.800.941.7045'
  }
  if (phoneNumber) {
    return phoneNumber
  } else {
    Sentry.captureException('Invalid phone number category', {
      extra: { category },
    })
    return PHONE_NUMBERS[defaultCategory]
  }
}
