import React from 'react'

export interface PageHeaderProps {
  author?: string
  /**
   * The category of the page to be shown in the header.
   * Example: 'RateQuote' or 'Car Insurance'
   */
  category?: string
  /**
   * The title of the page
   */
  title: string
  /**
   * The type of page on which the header is being used. Determines which subheader to render.
   */
  variant?: 'advertorial' | 'legal' | 'resource' | 'guide'
  /**
   * Date last updated/published
   */
  date?: string
  authorImage?: string
}

export const PageHeader = (props: PageHeaderProps) => {
  const { author, category, authorImage, date, title, variant } = props
  return (
    <header className="mt-7-1/2 lg:mb-13 lg:mt-17 mb-11 flex flex-col items-center sm:mt-0">
      <div className="mx-auto max-w-5xl">
        {category && (
          <p className="font-roboto text-blue-primary mb-3 text-center text-lg font-medium">
            {category}
          </p>
        )}
        <h1
          className={`font-circular text-4-1/2xl text-blue-navy  text-center font-normal ${
            variant === 'guide'
              ? 'leading-302 sm:leading-400 lg:leading-400 sm:text-3xl lg:text-5xl'
              : 'leading-302 sm:leading-400 lg:leading-536 sm:text-6xl lg:text-7xl'
          }`}
        >
          {title}
        </h1>
        {variant && (
          <div className="text-blue-navy font-roboto mt-4 text-center text-xl font-normal sm:mt-5 lg:mt-6">
            {(variant === 'advertorial' || variant === 'guide') && (
              <div
                className={`${
                  variant === 'guide' && 'ml-0 flex items-center justify-center'
                }`}
              >
                {authorImage && (
                  <img
                    src={authorImage}
                    alt="RateQuote Direct Guides Author"
                    className="mr-2 md:mr-5"
                  />
                )}
                <p
                  style={{ color: '#000' }}
                  className={`${variant === 'guide' && 'text-center'}`}
                >
                  {author && `${author}, `}
                  {getDate(variant, date)}
                </p>
              </div>
            )}
            {variant === 'legal' && (
              <p className="text-center">
                LAST UPDATED {getDate(variant, date)}
              </p>
            )}
            {variant === 'resource' && (
              <p className="text-center">Published {getDate(variant, date)}</p>
            )}
          </div>
        )}
      </div>
    </header>
  )
}

const getDate = (
  variant: PageHeaderProps['variant'],
  date: string | undefined,
) => {
  const displayDate = date ? new Date(date) : new Date()
  return (
    <time
      className={`${variant === 'legal' && 'uppercase'} inline-block`}
      dateTime={displayDate.toLocaleDateString()}
    >
      {displayDate.toLocaleString('en-US', {
        day: 'numeric',
        month: variant === 'legal' ? 'short' : 'long',
        year: 'numeric',
      })}
    </time>
  )
}
