import Link from 'next/link'
import React from 'react'
import { useRouter } from 'next/router'

interface DisclaimerProps {
  category: string
  licenseNum?: string
  smid?: string
}

export const Disclaimer = (props: DisclaimerProps) => {
  const { licenseNum, smid } = props
  const sharedClasses = 'font-circular text-white text-sm text-opacity-50'

  const router = useRouter()


  
  const isMedicarePath = router.pathname.includes('medicare')

  return (
    <div className="bg-blue-navy z-10 flex py-7">
      <div className={`flex w-full flex-col ${sharedClasses}`}>
        <div>
          {/* Render the first paragraph only if the path does not include "medicare" */}
          {!isMedicarePath && (
            <p>
              By calling the number on your screen or clicking the "View Quotes" or
              "See My GreatQuotes" button on this page, you agree that you are
              expressly consenting via this chat/webform a Licensed Insurance Agent
              of RateQuote or
              <Link
                className="hover:text-blue-primary text-white text-opacity-80"
                href="/marketing-partners/"
              >
                {' '}
                RateQuote Marketing Partner
              </Link>{' '}
              to contact you regarding insurance products which may include Medicare
              Advantage, Medicare Supplement, and Medicare Part D plans and other
              health-related services, by telephone using an Automated Dialing
              System call, text, or email or an artificial or prerecorded voice or
              via text/SMS message, even if the telephone number is assigned to a
              landline, a telephone service or other service for which the called
              party is charged. This is a solicitation for insurance. RateQuote or
              RateQuote Marketing Partners may also contact you via email using any
              email address you provided. This authorization overrides any previous
              registration on a federal, state or corporate Do Not Call Registry or
              any internal RateQuote or RateQuote Marketing Partner privacy or
              solicitation preference you have previously expressed. You are not
              required to provide this authorization as a condition of purchasing
              goods or services from RateQuote or RateQuote Marketing Partners. I
              understand this request has been initiated by me and is an unscheduled
              contact request. I further understand that this request, initiated by
              me, is my affirmative consent to be contacted which is in compliance
              with all federal and state telemarketing and Do-Not-Call laws.
              <br />
              <br />
            </p>
          )}
          <div>
            Participating sales agencies represent Medicare Advantage [HMO, PPO
            and PFFS] organizations and stand-alone PDP prescription drug plans
            that are contracted with Medicare. Enrollment depends on the plan’s
            contract renewal.
            <br />
            RateQuote.com is powered by RateQuote.com LLC, a non-government
            entity. Not affiliated with or endorsed by any government agency.
            Callers will be directed to a licensed insurance agent. We provide a
            service that connects you to licensed insurance agencies and/or
            licensed insurance agents who will provide you the number of
            organizations and number of plans they offer in your area.
            <br />
            <br />
          </div>
          <div>
            Enrollment in the described plan type may be limited to certain
            times of the year unless you qualify for a special enrollment
            period.
          </div>
            {isMedicarePath && (
            <div>
              We do not offer every plan available in your area. 
              Currently we represent [insert number of organizations] 
              organizations which offer [insert number of plans] products 
              in your area. Please contact , 1–800–MEDICARE, or your local 
              State Health Insurance Program (SHIP) to get information on all of your options
              <br />
              <br />
            </div>)}
            {!isMedicarePath && (
            <div>
              We do not offer every plan available in your area. Currently, we
              represent 10 organizations which offer 3 products in
              your area. Please contact Medicare.gov, 1–800–MEDICARE, or your
              local State Health Insurance Program (SHIP) to get information on
              all of your options
              <br />
              <br />
            </div>)}
        </div>
        {licenseNum && (
          <p
            className={`mt-4 mb-1 md:mt-6 md:mb-2 ${sharedClasses}`}
          >{`License #: ${licenseNum}`}</p>
        )}
        {smid && <p className={sharedClasses}>{smid}</p>}
      </div>
    </div>
  )
}
