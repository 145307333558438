import React, { useMemo } from 'react'
import { NextSeo } from 'next-seo'
import { useSiteMetadata } from '@/hooks'

interface Props {
  description?: string
  title: string
  url?: string
  image?: string
}

export function SEO({ description = '', title, url, image }: Props) {
  const { description: defaultDescription, isProd } = useSiteMetadata()
  const combinedDescription = description || defaultDescription
  const defaultImage = image || 'https://ratequote.com/static/logos/rq-logo.png'

  // Memoizing the default URL
  const defaultURL = useMemo(() => {
    if (typeof window !== 'undefined') {
      return window.location.origin + window.location.pathname
    }
    return ''
  }, [])

  // Combine props and defaults
  const metaURL = url || defaultURL

  return (
    <NextSeo
      title={title}
      description={combinedDescription}
      canonical={metaURL}
      openGraph={{
        url: metaURL,
        images: [
          {
            url: defaultImage,
            alt: `${title} | RateQuote`, // Improved alt text
          },
        ],
        title,
        description: combinedDescription,
        site_name: 'RateQuote',
        type: 'website',
      }}
      twitter={{
        handle: '@rate_quote',
        site: '@rate_quote',
        cardType: 'summary_large_image',
      }}
      facebook={{
        appId: isProd ? '2962540850551553' : '', // Empty for non-prod environments
      }}
      additionalMetaTags={[
        {
          name: 'language',
          content: 'en',
        },
        {
          name: 'robots',
          content: isProd ? 'index, follow' : 'noindex, nofollow',
        },
        {
          name: 'author',
          content: 'RateQuote',
        },
      ]}
    />
  )
}

SEO.defaultProps = {
  description: '',
}
