/**
 * Use this utility hook to get the site metadata.
 * Example: `const { siteMetadata } = useSiteMetadata();`
 */
export function useSiteMetadata(): {
  description: string
  isProd: boolean
  mediaAlphaRoot: string
} {
  const isProd = process.env.DEPLOYMENT_TARGET === 'production'

  return {
    description:
      'RateQuote is the leading provider of shopping for health insurance plans across the marketplace. Our RateQuote Direct licensed agents will review and compare health benefits across carriers to find the best one for you and your family.',
    isProd,
    mediaAlphaRoot: isProd
      ? 'insurance.mediaalpha.com'
      : 'insurance-test.mediaalpha.com',
  }
}
