import React, { useContext } from 'react'
import { useRouter } from 'next/router'

import { Button, Dropdown, DropdownOption, InputAction } from '../../'
import { verifyCategory } from '@/utils'
import { RateQuote, Sanity } from '../../../../types'
import { Context } from '../../../context'

export const CustomDropdownAction = ({
  value,
}: {
  value: Sanity.DropdownAction
}) => {
  const router = useRouter()
  const ctx = useContext<RateQuote.AppState>(Context)

  const [dropdownOption, setDropdownOption] = React.useState<DropdownOption>(
    value.dropdownOptions[0],
  )

  React.useEffect(() => {
    const cmsDefaultOption = value.dropdownOptions.find((i) => i.isDefault)
    const categoryOption = value.dropdownOptions.find(
      (i) => i.value === ctx.user?.category,
    )
    const defaultOption = categoryOption || cmsDefaultOption
    setDropdownOption(defaultOption || value.dropdownOptions[0])
  }, [value.dropdownOptions, ctx.user?.category])

  const onSubmit = () => {
    const path = dropdownOption.url || value.buttonUrl
    const [pathname, search] = path.split('?')
    console.log(pathname, search, 'pathname')

    router.push({
      pathname,
      search: search ? `?${search}` : '',
    })
  }

  const onChange = (newDropdownOption: DropdownOption) => {
    setDropdownOption(newDropdownOption)
    const categoryVerified = verifyCategory(String(newDropdownOption.value))
    if (categoryVerified) {
      ctx.setState({
        ...ctx,
        user: {
          ...ctx.user,
          category: categoryVerified,
        },
      })
    }
  }

  return (
    <div className="mx-auto w-full max-w-sm">
      <InputAction
        buttonComponent={
          <Button
            icon={
              <img
                alt="magnifier search icon"
                className="mr-2 block h-6 w-5"
                src="/static/icons/search.svg"
              />
            }
            id="get-started-btn"
            onClick={onSubmit}
          >
            {value.buttonText}
          </Button>
        }
        inputComponent={
          <Dropdown
            id="vertical-offerings"
            key="dropdown-verticals"
            name="vertical-offerings"
            onChange={(val: DropdownOption) => onChange(val)}
            options={value.dropdownOptions}
            value={dropdownOption}
          />
        }
        inputClasses="w-28 xxs:w-32"
      />
    </div>
  )
}
