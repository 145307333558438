import React, { useContext } from 'react'
import {
  CookieDisclaimer,
  DirectNavBar,
  Footer,
  NavBar,
  LandingNavbar,
  LandingFooter,
} from '../..'
import { RateQuote } from '../../../../types'
import { Context } from '../../../context'
import { getLicenseNumFromCategory } from '@/utils'
import { Segment } from '../../../../types'

export interface PrimaryLayoutProps {
  /**
   * Children components passed in between the Navbar and Footer
   */
  children?: React.ReactNode
  /**
   * Displays RQ Direct NavBar
   */
  isDirect?: boolean
  /** Pass minimal prop when rendering PrimaryLayout on a General Interior page
   * where no background images are present. It will remove the classes that
   * are only needed for the background images.
   */
  minimal?: boolean
  isLanding?: boolean
  category?: Segment.Category
  smid?: string
  isNewCTA?: boolean
  liveAgents?: number
  partnerLogo?: string
}

const NavbarSelector = (props: PrimaryLayoutProps) => {
  const { isDirect, isLanding, category, partnerLogo } = props

  if (isDirect) {
    return <DirectNavBar />
  }

  if (isLanding) {
    return <LandingNavbar partnerLogo={partnerLogo} />
  }

  return <NavBar hideItems={isLanding} category={category} />
}

export const PrimaryLayout = (props: PrimaryLayoutProps) => {
  const { children, minimal = false } = props
  const ctx = useContext<RateQuote.AppState>(Context)

  // Prioritize the category prop over the context value
  const resolvedCategory = props.category || ctx.user?.category

  return (
    <div
      className={`bg-white-bg relative flex min-h-screen flex-col ${
        !minimal ? 'overflow-x-clip' : ''
      }`}
    >
      <NavbarSelector {...props} category={resolvedCategory} />
      <main
        className={`${
          props.isLanding ? '' : 'max-w-8xl sm:px-7'
        } mx-auto flex w-full grow flex-col justify-between `}
      >
        {children}
      </main>
      <CookieDisclaimer />
      {props.isLanding ? (
        <LandingFooter
          {...{
            ...getLicenseNumFromCategory(resolvedCategory),
            smid: props.smid,
          }}
        />
      ) : (
        <Footer {...getLicenseNumFromCategory(resolvedCategory)} />
      )}
    </div>
  )
}
