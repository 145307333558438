import React from 'react'
import { useRouter } from 'next/router'

import { Button, InputAction, InputBox } from '../../index'
import { trackQuoteInput } from '@/clients/segment'
import { useLeadData } from '@/hooks'
import {
  categoryHasForm,
  getFormFromCategory,
  handlePhoneOut,
  pathForNextStep,
  progress,
  validateZipcode,
} from '@/utils'
import { Segment } from '../../../../types/segment'
import { isCallCenterOpen } from '@/utils/call-center'
import Image from 'next/image'

export interface BottomCTAProps {
  /**
   * Text to be rendered on the Input Action button
   */
  buttonText: string
  /**
   * The category of the page
   */
  category: Segment.Category
  /**
   * Placeholder text to be rendered in the input
   */
  inputPlaceholder: string
  phoneNumber: string
  /**
   * Text to display beneath the button, before the phone number
   */
  subtext?: string
  /**
   * The title of the CTA
   */
  title: string
  tty: string
}

export const AdvertorialBottomCTA = (props: BottomCTAProps) => {
  const {
    buttonText,
    category,
    inputPlaceholder,
    phoneNumber,
    subtext,
    title,
    tty,
  } = props

  const router = useRouter()

  const form = getFormFromCategory(category) || 'get-quote'
  const formExists = categoryHasForm(category)

  const { step } = progress('', form)

  const [leadZipcode, setLeadZipcode] = useLeadData<'zipcode'>('zipcode')
  const [zipcode, setZipcode] = React.useState(leadZipcode as string)

  const onChangeZipcodeInput = (val: string) => {
    if (!/\D/.test(val)) {
      setZipcode(val)
    }
  }

  const onSubmit = () => {
    if (validateZipcode(zipcode)) {
      setLeadZipcode(zipcode)
      trackQuoteInput({ step, name: 'zip', value: zipcode })
      router.push(pathForNextStep(step, form, { zipcode }))
    } else {
      router.push(`/${form}`, `/${form}`, {
        shallow: false,
      })
    }
  }

  return (
    <div className="relative flex w-full flex-col">
      <div className="z-10 mx-auto my-8 py-4 text-center">
        <p className="text-blue-navy font-circular mx-auto max-w-screen-sm text-center text-3xl font-bold lg:text-4xl">
          {title}
        </p>
        {isCallCenterOpen() && formExists && buttonText && buttonText !== '' ? (
          <>
            <div className="max-w-80 md:max-w-84 mx-auto my-10 lg:my-16">
              <InputAction
                buttonComponent={
                  <Button onClick={onSubmit}>{buttonText}</Button>
                }
                inputComponent={
                  <InputBox
                    iconLeft="/static/icons/location-spot.svg"
                    id="get-started-input"
                    inputMode="numeric"
                    key="input--zipcode"
                    labelId="get-started-btn"
                    maxLength={5}
                    name="get-started-input"
                    onChange={onChangeZipcodeInput}
                    pattern="[0-9]*"
                    placeholder={inputPlaceholder}
                    type="tel"
                    value={zipcode}
                  />
                }
              />
            </div>
            <div className="align-center font-roboto flex flex-col items-center justify-center">
              <p className="text-grayscale-5 my-auto">{subtext}</p>
              <a
                className="text-blue-primary mt-2 flex text-xl no-underline"
                href={`tel:+${phoneNumber}`}
                onClick={() => handlePhoneOut({ phoneNumber, category })}
              >
                <img
                  className="mr-3-3/4 h-6 w-5"
                  alt="phone"
                  src="/static/icons/phone-cta-blue.svg"
                />
                {phoneNumber}
                {tty}
              </a>
            </div>
          </>
        ) : (
          <>
            <div className="mx-auto my-10 w-full sm:w-fit">
              <a
                className="text-white no-underline"
                href={`tel:+${phoneNumber}`}
              >
                <Button
                  classes={tty ? 'text-base xxs:text-lg' : ''}
                  icon={
                    <img
                      className={`${tty ? 'xxs:mr-4 mr-2' : 'mr-4'} h-6 w-5`}
                      alt="phone"
                      src="/static/icons/telephone-white.svg"
                    />
                  }
                  onClick={() => handlePhoneOut({ phoneNumber, category })}
                  role="primary"
                >
                  {phoneNumber}
                  {tty}
                </Button>
              </a>
            </div>
            <p className="text-grayscale-5 mx-auto text-center">{subtext}</p>
          </>
        )}
      </div>
    </div>
  )
}
