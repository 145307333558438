import React from 'react'
import * as Sentry from '@sentry/nextjs'
import type { AppProps } from 'next/app'
import Head from 'next/head'
import { useRouter } from 'next/router'
import { config } from '@fortawesome/fontawesome-svg-core'
import '@fortawesome/fontawesome-svg-core/styles.css'
import { PortableTextComponentsProvider } from '@portabletext/react'
import { SanitySerializers } from '../components'
import '../style.css'
import { RateQuote } from '../../types'
import { Context, setStateContext } from '../context'
import RingbaController from '../components/atoms/RingbaController'
import getContextFromUrl from '../utils/context/getContextFromUrl'
import { TailwindIndicator } from '@/components/atoms/TailwindIndicator'
import Popup from '@/components/molecules/CallModal'
config.autoAddCss = false

function AppWrapper({ Component, pageProps }: AppProps) {
  const router = useRouter()

  const [state, setState] = React.useState<RateQuote.AppState>({
    user: {
      anonymousId: undefined,
      category: 'u65',
    },
    clientLocation: {
      country: '',
      state: '',
      status: '',
    },
    ringba: {
      numberPoolId: '',
    },
  })

  const getLocation = (state: RateQuote.AppState) => {
    if (process.env.NODE_ENV === 'development') return

    fetch('/api/get-location/')
      .then((res) => {
        if (res.status === 200) {
          return res.json()
        } else {
          setState({
            ...state,
            clientLocation: { country: '', state: '', status: 'done' },
          })
        }
      })
      .then((res) => {
        setState({
          ...state,
          clientLocation: {
            country: res.country,
            state: res.state,
            status: 'done',
          },
        })
      })
      .catch((err) => {
        Sentry.captureException(err)
        setState({
          ...state,
          clientLocation: {
            country: '',
            state: '',
            status: 'done',
          },
        })
      })
  }

  // on component mount
  React.useEffect(() => {
    try {
      const _state = {
        ...state,
        ...{ user: { anonymousId: getUserId(), category: 'u65' } },
        ...getContextFromUrl(),
      }
      setState(_state)
      getLocation(_state)
    } catch (e) {
      Sentry.captureException(e)
    }
  }, [])

  const handleRouteChange = (url: any) => {
    window.analytics.page(url)
  }

  // on route change
  React.useEffect(() => {
    try {
      router.events.on('routeChangeComplete', handleRouteChange)
    } catch (e) {
      Sentry.captureException(e)
    }

    // cleanup
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [router])

  const getUserId = () => {
    if (typeof window !== 'undefined') {
      const id = window?.analytics?.user?.().anonymousId?.()
      return id
    } else {
      return undefined
    }
  }

  return (
    <>
      <Head>
        <title>RateQuote</title>
        <link
          as="font"
          crossOrigin="anonymous"
          href="/fonts/Circular/CircularStd-Book.otf"
          rel="preload"
        />
        <link
          as="font"
          crossOrigin="anonymous"
          href="/fonts/Circular/CircularStd-Medium.otf"
          rel="preload"
        />
        <link
          as="font"
          crossOrigin="anonymous"
          href="/fonts/Circular/CircularStd-Bold.otf"
          rel="preload"
        />
      </Head>
      <div>
        <Context.Provider
          value={{
            user: state.user,
            clientLocation: state.clientLocation,
            ringba: state.ringba,
            setState: (value: RateQuote.AppState) => {
              setStateContext({ value, setState })
            },
          }}
        >
          <PortableTextComponentsProvider components={SanitySerializers}>
            <Component {...pageProps} />
            <RingbaController />
          </PortableTextComponentsProvider>
        </Context.Provider>
      </div>
      <Popup /> {/* Add the Popup component here */}
      <TailwindIndicator />
    </>
  )
}

export default AppWrapper
